import {createRouter, createWebHistory} from "vue-router";

const routes = [
  {
    path: '/',
    component: () => import('@/pages/RecruitmentContent.vue'),
    meta: {title: '投教基地招募'}
  },
  {
    path: '/apply_form',
    component: () => import('@/pages/ApplyForm.vue'),
    meta: {title: '报名名单'}
  },
  {
    path: '/training_session',
    component: () => import('@/pages/TrainingSession.vue'),
    meta: {title: '培训会'}
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  //使用浏览器的回退或者前进时，重新返回时保留页面滚动位置，跳转页面的话，不触发
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
      return savePosition;
    } else {
      return {top: 0}
    }
  }
})
router.beforeEach((to, from, next) => {
  next();
});
export default router
